<template>
    <main class="blog-category-page">
        <h1 class="blog-category-page__title">Últimos artículos de {{ info.data.blogCategory.name }}</h1>

        <section>
            <ul class="blog-category-page__blog-posts">
                <li v-for="(data, index) in info.data.articles.data" :key="index" class="post">
                    <BCardBasic
                        :post="data"
                        :categorySlug="Route.params.category as string"
                        :blog-tag="info.data.blogCategory.name"
                    />
                </li>
            </ul>
        </section>
    </main>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'

const {
    $lang,
    $metaData: { siteOrigin },
} = useNuxtApp()
const Route = useRoute()
const { buildHeaders, baseUrl, endpoints } = useApiClient()

const getCategory = (page?: number) =>
    $fetch<Api.Responses.Pages.ArticlesByCategory>(
        endpoints.pages.articlesByCategory.path.replace('_SLUG_', Route.params.category as string),
        {
            headers: buildHeaders(),
            method: 'GET',
            baseURL: baseUrl,
            params: {
                page: page || 1,
                length: 1000,
            },
        },
    )

const { data: responseData, error } = await useAsyncData('blog-index-data', () => {
    return getCategory().catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}
const info = ref(responseData.value)

const title = $lang.pages.blogCategory.articles_about.replace(
    '{{category}}',
    info.value.data.blogCategory.name,
)
const description = $lang.pages.blogCategory.all_articles.replace(
    '{{category}}',
    info.value.data.blogCategory.name,
)

const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.blog-category-page {
    @apply container mb-12 space-y-5;

    &__title {
        @apply my-3 text-center text-[28px] font-medium md:text-[32px];
    }

    &__content {
        @apply text-lg;
    }

    &__blog-posts {
        @apply grid grid-cols-1 gap-x-5 gap-y-10 xs:grid-cols-2 md:grid-cols-3;
    }
}
</style>
